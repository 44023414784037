import React, { Component } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { IconSearch } from '../../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';

import FilterForm from '../FilterForm/FilterForm';

import css from './KeywordFilterPlain.module.css';

class KeywordFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keywords: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { onSubmit } = this.props;
    onSubmit(values);
  }

  handleChange(values) {
    this.setState({ keywords: values.keywords });
  }

  handleClear() {
    const { onSubmit, onClear } = this.props;

    if (onClear) {
      onClear();
    }

    this.setState({ keywords: null });
    onSubmit(null);
  }

  render() {
    const {
      rootClassName,
      className,
      plainClassName,
      id,
      children,
      initialValues,
      keepDirtyOnReinitialize,
      contentPlacementOffset,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const submitDisabled = !this.state.keywords || this.state.keywords === '';
    const clearFiltersVisible =
      this.state.keywords && this.state.keywords !== '' && initialValues.keywords;

    return (
      <div className={classes}>
        <div
          id={id}
          className={classNames(plainClassName, css.plain)}
          ref={node => {
            this.filterContent = node;
          }}
        >
          <FilterForm
            id={`${id}.form`}
            liveEdit
            contentPlacementOffset={contentPlacementOffset}
            onChange={this.handleChange}
            initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            paddingClasses={css.filterFormContent}
          >
            {children}
            {clearFiltersVisible ? (
              <button className={css.clearButton} onClick={this.handleClear}>
                <FormattedMessage id="KeywordFilterPlain.closeSymbol" />
              </button>
            ) : null}
          </FilterForm>
          <button
            className={css.submitButton}
            onClick={() => this.handleSubmit({ keywords: this.state.keywords })}
            disabled={submitDisabled}
            ref={node => {
              if (node) {
                node.style.setProperty('min-width', '100px', 'important');
              }
            }}
          >
            <IconSearch className={css.searchIcon} />
          </button>
        </div>
      </div>
    );
  }
}

KeywordFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  plainClassName: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
};

KeywordFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  plainClassName: string,
  id: string.isRequired,
  onSubmit: func.isRequired,
  children: node.isRequired,
  initialValues: object,
  keepDirtyOnReinitialize: bool,

  // form injectIntl
  intl: intlShape.isRequired,
};

const KeywordFilterPlain = injectIntl(KeywordFilterPlainComponent);

export default KeywordFilterPlain;
